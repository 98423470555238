/*
 * @Description: 轮播图详情
 */
import https from '@/utils/https'
import { RootObject } from '@/model/rootObject'
import { ContentType, Method } from 'axios-mapper'
import { CarouselDetailItem } from '@/model/carouselDetail'

// 获取详情
export const getDetail = (id: string) => {
  return https().request<RootObject<CarouselDetailItem>>(`api/homePage/${id}`, Method.GET, undefined, ContentType.form)
}
